<template>
  <ValidationObserver ref="form" v-slot="{ validate, validated, valid, invalid, errors }" tag="div">
    <b-form @submit.prevent="validate().then(onSubmit($event))" @reset="resetForm" v-bind="{ valid, invalid, validated, errors }" :class="$attrs.class" novalidate :validated="false">
      <slot v-bind="{ valid, validated, invalid, errors, formIsReadonly }"></slot>
      <div v-if="!noButtons" class="btn-set mb-0 mt-3">
        <slot name="edit-buttons" v-bind="{ valid, validated, invalid, errors, editMode, formIsReadonly }" v-if="(editMode || forceEditMode) && !readonly ">
          <b-button class="btn btn-default btn-primary" :disabled="invalid && validated" variant="primary" type="submit">{{ submitLabel }}</b-button>
          <b-button v-if="showReset" type="reset" class="btn btn-default btn-red">Reset</b-button>
          <a v-if="showCancel" class="btn btn-default btn-light-gray" href="#" @click="cancelEditMode">Cancel</a>
        </slot>
        <slot name="readonly-buttons" v-bind="{ valid, validated, invalid, errors, editMode, formIsReadonly }" v-if="!editMode && !readonly && !forceEditMode">
          <a :disabled="readonly" class="btn btn-default btn-primary" href="#" @click="startEditMode">Edit</a>
        </slot>
      </div>
    </b-form>
  </ValidationObserver>
</template>
<script>

import './validations.js'

export default {
  inheritAttrs: false,
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    noButtons: {
      type: Boolean,
      default: false
    },
    successMessage: {
      type: String,
      default: 'Saved successfully'
    },
    submitLabel: {
      type: String,
      default: 'Submit'
    },
    reportSuccess: {
      type: Boolean,
      default: true
    },
    startInEditMode: {
      type: Boolean,
      default: false
    },
    forceEditMode: {
      type: Boolean,
      default: false
    },
    useFlashForErrors: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showReset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { editMode: this.startInEditMode }
  },
  computed: {
    formIsReadonly() {
      return !this.editMode || this.readonly
    }
  },
  methods: {
    changeEditMode(newMode) {
      this.editMode = newMode
      this.$emit('update:edit-mode', newMode)
    },
    cancelEditMode() {
      this.changeEditMode(false)
    },
    startEditMode() {
      this.changeEditMode(true)
    },
    onSubmit(e) {
      var externalSubmit
      if (this.$parent) {
        if (this.$parent.submit) {
          externalSubmit = this.$parent.submit
        } else {
          if (this.$parent.$parent) {
            if (this.$parent.$parent.submit) {
              externalSubmit = this.$parent.$parent.submit
            }
          }
        }
      }

      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          externalSubmit(e).then((response) => {
            if (this.reportSuccess) {
              this.$flasher.success(this.successMessage)
            }
            this.$emit('saved')
            this.editMode = this.forceEditMode
          }, (result) => {
            if (result) {
              var errors = result
              if (result.response) {
                errors = result.response.data
              } else {
                if (result.data) {
                  errors = result.data
                }
              }
              if (errors.error_hash) {
                this.$refs.form.setErrors(errors.error_hash)
                if (errors.error_hash.error) {
                  if (this.useFlashForErrors) {
                    this.$flasher.errors(errors.error_hash.error)
                  }
                }
              }
              if (errors.errors) {
                if (this.useFlashForErrors) {
                  this.$flasher.errors(errors.errors)
                }
              } else {
                console.error('POSSIBLE BUG: NO error_hash or errors RETURNED BUT THE REQUEST FAILED', errors)
              }
            } else {
              if (result !== false) {
                console.error("Form rejected result: '" + result + "'")
              }
            }
          })
        }
      })
    },
    resetForm() {
      if (this.$parent.resetForm) {
        this.$parent.resetForm()
      }
      requestAnimationFrame(() => {
        this.$refs.form.reset()
      })
    },
    setFormErrors(error_hash) {
      this.$refs.form.setErrors(error_hash)
    }
  },
  watch: {
    readonly(newValue) {
      if (newValue === true) {
        this.editMode = this.startInEditMode
      }
    },
    startInEditMode(newValue) {
      if (newValue === true) {
        this.editMode = true
      }
    }
  }
}
</script>
